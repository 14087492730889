import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 170px;
    max-height: 198px;

    .area-img-titulo{
        display: block;
    }

    .area-img{
        display: flex;
        width: 150px;
        height: 150px;
        
        border-radius: 50%;

        img{
            width: 100%;
            height: auto;
        }
    }

    .area-titulo{
        display: flex;
        text-align: center;
        width: 100%;
        margin-top: 10px;


        span{            
            width: 100%;

            a{
                text-decoration: none;
                color: white;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }

        :hover{
            cursor: pointer;
            color: #e3023c;
        }
    }
`;