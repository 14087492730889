import styled from 'styled-components';
//Verificar aqui pois pode ser DIv e não main
export const Container = styled.main`
    display: block;
    width: 100%;
    height: auto;
    background-image: linear-gradient(var(--secondaryColor), var(--mainColor));

    .area-publicidade{
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 5px 0px 5px 0px;
    }

    .publicidade-lider{
        display: none;
        width: 100%;
        max-width: 728px;
        height: 90px;
        background-color: aliceblue;
    }
    
`;

