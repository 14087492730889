import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 1000px;
    height: auto;
    

    
    .cont-carousel{
        display: flex;
        width: 100%;
        height: 100%;
    }

    .carousel{
        width: 100%;
        padding: 3px;
        height: 100%;
    }

    .card-test{
        width: 50px;
        height: 50px;
        background-color: red;
        border: 1px solid white;
        margin-left: 4px;
    }

    
`;