import styled from "styled-components";

export const Container = styled.div`
    display: block;
    width: 100%;
    max-width: 1040px;
    min-height: 1000px;
    

    .area-navegacao{
        display: flex;
        margin-top: 5px;
        width: 100%;
        height: 25px;
       
        color: white;
    }
`;

export const ContAreaCards = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 5px;
    
    
`;

export const AreaCards = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    

    .card-area-categoria{
        margin-left: 5px;
        
        margin-bottom: 5px;
    }

    @media (max-width: 1040px) {
        justify-content: center;

        .card-area-categoria{
            margin-left: 1%;
            margin-bottom: 1%;
        }
    }

`;




export const AreaTitle = styled.div`
    display: flex;
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-top: 5px;


    .divisor {
        width: 07px;
        height: 25px;
        background-color: white;
        margin-right: 5px;
    }

    .title{
        span{
            color: #e3023c;
            font-size: 18px;
            font-weight: bold;
        }   
    }

    /* Telas pequenas (smartphones) */
    @media (max-width: 600px) {
        .title{
            span{
                font-size: 16px;
            }   
        }
    }


`;
