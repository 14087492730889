import {BreadcrumbContainer} from './styles';



const Breadcrumbs = ({ paths }) => {
  return (
    <BreadcrumbContainer>
      {paths.map((path, index) => (
        <div className="area-breadcrumb" key={index}>
          {path.href ? <a href={path.href}>{path.label}</a> : path.label}
          {index < paths.length - 1 && <span className="barra">/</span>}
        </div>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumbs;
