import React from "react";
import { Container } from './styles'

const Botao = (props) => {
    return (
        <Container $color={props.color}>{props.title}</Container>
    );
}

export default Botao;
