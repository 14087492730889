import React, { useEffect, useState } from 'react';
import { Container, ButtonCompreAgora, CheckIcon, FaqSection, FaqItem, FaqTitle, FaqContent } from './styles';
import Breadcrumbs from '../../components/Breadcrumbs/index';
import Loading from '../../components/Loading/Loading';
import ConteudoMain from '../../components/ConteudoMain';
import CarouselMain from '../../components/Carousel/CarouselMain';
import { useParams } from 'react-router-dom';
import { produtos } from '../../dados/produtos';
import Layout from '../../components/Layout';

const Detalhe = () => {

  const { slug } = useParams();
  const [produto, setProduto] = useState(null);
  const [dataRecomendados, setDataRecomendados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [openIndex, setOpenIndex] = useState(null);
  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    // Simulando a busca do produto pelo slug
    const foundProduto = produtos.find(p => p.slug === slug);
    if (foundProduto) {
      setProduto(foundProduto);
      // Simulando a busca dos produtos recomendados
      const recomendados = produtos.filter(p => p.categorias.includes(6)); // Categoria 6: Recomendado para Você
      setDataRecomendados(recomendados);
    } else {
      setError('Produto não encontrado');
    }
    setLoading(false);
  }, [slug]);

  if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;
  if (!produto) return null;

  const breadcrumbPaths = [
    { label: 'Home', href: '/' },
    { label: produto.titulo }
  ];


  return (
    <Layout>
      <Container>
        <div className="breadcrumb-container">
          <Breadcrumbs paths={breadcrumbPaths} />
        </div>

        <div className="hero-section">
          <h1>{produto.titulo}</h1>
          <p>{produto.subTitulo}</p>
          <img src={produto.imgPrincipal} alt={produto.imgPrincipalAlt} className="course-image" />
          <iframe src={produto.urlVideo} title="Video do curso" className="course-video" allowFullScreen></iframe>
        </div>

        <div className="description-section">
          <div className="description-area-title">
            <h2>Descrição do Curso</h2>
            <h3>{produto.tituloDescricao}</h3>
          </div>
          <div className="description-conteudo">
            <p>{produto.descricao}</p>
          </div>
          <div className="description-course-price">
            <p className="course-price">Preço: R$ {produto.precoAtual}</p>
            <p className="course-price-parcela">{produto.parcelaTexto}</p>
            <div className="description-area-button">
              <a href={produto.linkCompra} target="_blank" rel="noopener noreferrer">
                <ButtonCompreAgora>Compre Agora</ButtonCompreAgora>
              </a>
            </div>
          </div>
        </div>
        
        <div className="benefits-section">
          <h2>O que você vai aprender</h2>
          <ul>
            {produto.beneficios.map((beneficio, index) => (
              <li key={index}><CheckIcon /> {beneficio}</li>
            ))}
          </ul>
        </div>

        <div className="resultados-section">
          <h2>{produto.tituloResultados}</h2>
          <div className="cont-result-images">
            <div className="result-images">
              {produto.resultados.map((resultado, index) => (
                <div className="card-result-images" key={index}>
                  <img src={resultado} alt={`Resultado ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="author-section">
          <h2>{produto.tituloAutor}</h2>
          <img src={produto.authorImage} alt={produto.authorImageAlt} className="author-image" />
          <p>{produto.authorDescription}</p>
        </div>

        <div className="testimonials-section">
          <h2>Depoimentos de Alunos</h2>
          {produto.testimonials.map((testimonial, index) => (
            <div className="testimonial" key={index}>
              <p>"{testimonial.text}" - {testimonial.author}</p>
            </div>
          ))}
        </div>

        <div className="cta-section">
          <h2>INSCREVA-SE AGORA!</h2>
          <p>Aproveite esta oportunidade única e transforme sua carreira.</p>
          <div className="area-button">
            <a href={produto.linkCompra} target="_blank" rel="noopener noreferrer">
              <ButtonCompreAgora>Compre Agora</ButtonCompreAgora>
            </a>
          </div>
        </div>

        <FaqSection>
          <h2>PRINCIPAIS DÚVIDAS</h2>
          {produto.faq.map((item, index) => (
            <FaqItem key={index} onClick={() => toggleFaq(index)}>
              <FaqTitle>{item.question}</FaqTitle>
              {openIndex === index && <FaqContent>{item.answer}</FaqContent>}
            </FaqItem>
          ))}
        </FaqSection>

        <div className="conteudoMain">
          <ConteudoMain linkVerTudo={`/produto/categoria/recomendado-para-voce`} title="Recomendado para Você" conteudo="Carousel 1" backgroundimage="linear-gradient(var(--secondaryColor), var(--mainColor))">
            <CarouselMain produtos={dataRecomendados} slidesToshow={3.47} />
          </ConteudoMain>
        </div>
      </Container>
    </Layout>
  );
};

export default Detalhe;