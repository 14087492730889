import styled from 'styled-components';
import { Check2Square } from '@styled-icons/bootstrap/Check2Square';


export const ButtonCompreAgora = styled.button`
    background-color: #33328F;
    color: white;
    font-size: 1.5em;
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #106a97;
    }
`;



export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding-top: 20px;



    .breadcrumb-container {
        width: 100%;
        max-width: 1200px;
        margin-bottom: 20px;
        background-color: #1785C2;
        height: 45px;
        border-radius: 10px;
        padding-left: 5px;
    }

    .description-section,
    .benefits-section,
    .testimonials-section,
    .faq-section,
    .author-section,
    .resultados-section,
    .cta-section,
    .hero-section {
        width: 100%;
        max-width: 1200px;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;

        h2{
            font-size: 2em;
            color: #333;
        }
    }

    .hero-section {
        text-align: center;
        padding: 40px 20px;
        
        h1 {
            font-size: 2.5em;
            margin-bottom: 10px;
            color: #333;
        
        }

        p {
            font-size: 1.2em;
            color: #555;
        }

        .course-image{
            width: 100%;
            max-width: 600px;
            max-height: 600px;
        }

        .course-video {
            width: 100%;
            max-width: 700px;
            margin: 20px auto;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        .course-video {
            height: 315px;
            border: none;
        }
    }

    .description-section{

        .description-area-title{
            text-align: center;

            h3{
                margin-top: 5px;
            }
        }

        .description-conteudo{
            display: flex;
            width: 100%;
            margin-top: 5px;
            word-wrap: break-word; /* Quebrar palavras longas */
            word-break: break-all; /* Quebrar palavras longas */
            white-space: normal; /* Permitir quebras de linha normais */
        }

        .description-course-price{
            text-align: center;
        }

        .description-area-button{
            margin-top: 5px;
            display: flex;
            width: 100%;
            justify-content: center;
        }
        
    }

    .benefits-section{
        width: 100%;
        max-width: 1200px;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;

        h2 {
            font-size: 2em;
            margin-bottom: 10px;
            color: #333;
        }

        ul {
            list-style-type: none;
            margin-left: 0;
            padding-left: 0;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
            }
        }
    }
    
  
    .course-price {
        font-size: 1.5em;
        color: #e63946;
        font-weight: bold;
        margin-top: 10px;
    }

    .course-price-parcela{
        color: blue;
    }

    .author-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .author-image {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin-bottom: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border: 2px solid #33328F;
        }

        p {
            text-align: center;
            font-size: 1.2em;
        }
    }

    .resultados-section {
        display: block;
        text-align: center;

        .cont-result-images{
            display: block;
        }

        .result-images {

            display: flex;
            justify-content: center;
            margin-top: 20px;
            
            .card-result-images{

                margin-left: 10px;

                img {
                    width: 100%;
                    max-width: 300px;
                    max-height: 300px;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
            }
        
        }

        /* Telas pequenas (smartphones) */
        @media (max-width: 800px) {
            display: block;
            
            .cont-result-images{
                display: flex;
                width: 100%;
                justify-content: center;
            }

            .result-images {
                display: block;

                .card-result-images{
                    margin-top: 5px;
                    margin-left: 0px;
                }
            }
            
        }
    
    }

    .testimonials-section{
        h2 {
            text-align: center;
            font-size: 2em;
            margin-bottom: 10px;
            color: #333;
        }

        .testimonial {
            background-color: #f1f1f1;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 10px;

            p {
                font-size: 1.2em;
                line-height: 1.6;
                color: #333;
            }
        }
    }

    .cta-section{
        display: block;
        text-align: center;
        
        .area-button{
            margin-top: 5px;
            display: flex;
            width: 100%;
            justify-content: center;
        }
    }

    .conteudoMain{
        display: flex;
        align-items: center;
        width: 100%;
        height: 400px;
        background-color: var(--mainColor);  
    }

`;

export const FaqSection = styled.div`
    width: 100%;
    max-width: 1200px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    h2{
        text-align: center;
        font-size: 2em;
        margin-bottom: 10px;
        color: #333;
    }
`;

export const FaqItem = styled.div`
    margin-bottom: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    transition: all 0.3s ease;

    &:hover {
        background-color: #f9f9f9;
    }
`;

export const FaqTitle = styled.h4`
    font-size: 1.5em;
    margin-bottom: 5px;
    color: #33328F;
`;

export const FaqContent = styled.p`
    font-size: 1.2em;
    line-height: 1.6;
    color: #333;
    margin-top: 10px;
`;

export const CheckIcon = styled(Check2Square)`
    max-width: 35px;
    color: #33328F;
    margin-right: 10px;
`;