import styled from 'styled-components';


export const Container = styled.nav`
    grid-area: BarMain;
    display: flex;
    width: 100%;
    height: 80px;
    background-color: rgba(var(--azul-rgb), 0.8);
    position: fixed;
    top: 0;
    z-index: 10000; /* Certifique-se de que a barra esteja acima de outros conteúdos */
    
`;

export const AreaEmblema = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;

    .emblema{
        width: auto;
        height: auto;
        

        /* Telas pequenas (smartphones) */
        @media (max-width: 600px) {
            img{
                width: 100%; 
                height: auto;
            }
        }
    }
`;

export const LinkMenu = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;

    .cont-menu{
        display: flex;
        margin-right: 2%;

        a{            
            text-decoration: none;
            color: white;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            &:hover{
                color: red;
            }

        }
    }

`;