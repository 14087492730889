import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const FullStar = styled.div`
  color: white;
  font-size: 24px; /* Tamanho da estrela */
`;

export const EmptyStar = styled.div`
  color: lightgray;
  font-size: 24px; /* Tamanho da estrela */
`;
