
export const categorias = [
    { id: 1, nome: 'Finanças e Negócios', nomeUrl: 'financas-e-negocios', imgCard: '/imagens/negocios.png' },
    { id: 2, nome: 'Crescimento Pessoal', nomeUrl: 'crescimento-pessoal', imgCard: '/imagens/carreira.png'},
    { id: 3, nome: 'Educação', nomeUrl: 'educacao', imgCard: '/imagens/ensino.png'},
    { id: 4, nome: 'Saúde e Esportes', nomeUrl: 'saude-e-esportes', imgCard: '/imagens/saude.png'},
    { id: 5, nome: 'Marketing e Vendas', nomeUrl: 'marketing-e-vendas', imgCard: '/imagens/mark.png'},
    { id: 6, nome: 'Recomendado para Você', nomeUrl: 'recomendado-para-voce', imgCard: '/imagens/mark.png'},
    { id: 7, nome: 'Melhores Cursos Pagos', nomeUrl: 'melhores-cursos-pagos', imgCard: '/imagens/mark.png'},
    { id: 8, nome: 'Melhores Cursos', nomeUrl: 'melhores-cursos', imgCard: '/imagens/mark.png'},
    { id: 9, nome: 'Gratuitos', nomeUrl: 'melhores-cursos-gratuitos', imgCard: '/imagens/mark.png'},
];

  