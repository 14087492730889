import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Produto from './pages/Produto/index'; // Ajuste o caminho e nome do componente conforme necessário
import CategoriaDetail from './pages/Categoria/index';

const Routess = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/produto/:slug" element={<Produto />} />
      <Route path="/produto/categoria/:categoriaNomeUrl" element={<CategoriaDetail />} /> {/* Adicione esta linha */}
    </Routes>
  </BrowserRouter>
);

export default Routess;
