import React from 'react';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .loader {
    display: flex;
    justify-content: space-around;
    width: 100px;
    height: 40px;
  }

  .loader div {
    background-color: #007bff; /* Cor azul */
    height: 100%;
    width: 10px;
    margin: 0 2px;
    animation: grow 1.2s infinite ease-in-out;
  }

  .loader div:nth-child(1) {
    animation-delay: -1.2s;
  }

  .loader div:nth-child(2) {
    animation-delay: -1.1s;
  }

  .loader div:nth-child(3) {
    animation-delay: -1s;
  }

  .loader div:nth-child(4) {
    animation-delay: -0.9s;
  }

  .loader div:nth-child(5) {
    animation-delay: -0.8s;
  }

  @keyframes grow {
    0%, 40%, 100% {
      transform: scaleY(1);
    }
    20% {
      transform: scaleY(2);
    }
  }
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingContainer>
  );
};

export default Loading;
