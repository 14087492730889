import React from "react";
import {Container} from "./styles";

const CardRedondo = ({imgCard, altCard, titleCard, nomeUrl}) => {
    return(
        <Container>
            <div className="area-img-titulo">
                <div className="area-img">
                    <img src={imgCard} alt={altCard} />
                </div>
                <div className="area-titulo">
                    <span>
                        <a href={nomeUrl}>
                            {titleCard}
                        </a>
                    </span>
                </div>
            </div>
            
        </Container>
    );
}
export default CardRedondo;

