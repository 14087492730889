import React from "react";
import {Container} from "./styles";
import Slider from "react-slick";
import CardDestaque from "../../CardDestaque";


const Carousel = ({produtos}) => {

    //setttings do Slick
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3.8, //aqui
        slidesToScroll: 1,
        initialSlide: 0,
        className: "carousel",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3.7,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3.3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2.9,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 416,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 340,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 200,
                settings: {
                    slidesToShow: 0.5,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };

    return(
        <Container>
            <div className="cont-carousel">
                <Slider {...settings}>
                    {Array.isArray(produtos) && produtos.map((produto) => (
                        <div key={produto.slug}>
                            
                            <CardDestaque link={`/produto/${produto.slug}`}
                                title={produto.titulo}
                                imgCard={produto.imgCard} 
                                imgAlt={produto.imgCardAlt} 
                                precoAnterior={produto.precoAnterior}
                                precoAtual={produto.precoAtual}
                                rating={produto.rating}
                            />
                            
                        </div>
                    ))}
                </Slider>
            </div>
        </Container>
    );
}
export default Carousel;