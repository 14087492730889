import React from "react";
import { Container, AreaPrincipal, AreaTitle, AreaConteudo, AreaBotao} from "./styles";
import Botao from "../Botao";


const ConteudoMain = ({title, children, linkVerTudo}) => {

    return(
        <Container>
            <AreaPrincipal>
                <AreaTitle>
                    <div className="divisor"/>
                    <div className="title">
                        <span>{title}</span>
                    </div>
                </AreaTitle>
                <AreaConteudo>
                    {children}
                </AreaConteudo>
                <AreaBotao>
                    <a href={linkVerTudo}>
                        <Botao title="Ver Tudo"/> 
                    </a>
                </AreaBotao>
            </AreaPrincipal>
            
        </Container>
    );
}
export default ConteudoMain;