import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
`;

export const AreaPrincipal = styled.div`
    display: block;
    width: 100%;
    max-width: 1000px;
    height: auto;
`;

export const AreaTitle = styled.div`
    display: flex;

    width: 100%;
    max-width: 250px;
    height: auto;
    


    .divisor {
        width: 07px;
        height: 25px;
        background-color: white;
        margin-right: 5px;
    }

    .title{
        span{
            color: #e3023c;
            font-size: 18px;
            font-weight: bold;
        }   
    }

    /* Telas pequenas (smartphones) */
    @media (max-width: 600px) {
        .title{
            span{
                font-size: 16px;
            }   
        }
    }


`;

export const AreaConteudo  = styled.div`
    display: flex;
    width: 100%;
    height: 265px;
    
`;

export const AreaBotao  = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    a{
        width: 100%;
        max-width: 85px;
        text-decoration: none;
        color: white;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
`;


