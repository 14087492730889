import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    width: 250px;
    height: 250px;  //
    justify-content: center;
    align-items: center;
    position: relative;
    

    img{
        width: 100%;
        height: 100%;
        
    }

    .area-title{
        display: block;
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 3px;
        background-color: rgba(0, 0, 0, .5);
        
        border-radius: 5px;
        

        .titulo{
            display: flex;
            width: 100%;
            height: auto;
            background-color: rgba(0, 0, 0, .2);
            
            align-items: center;
            justify-content: center;

            span{
                text-align: center;
            }
        }

        .avaliacao{
            display: flex;
            width: 100%;
            
            justify-content: center;
        }

        .preco{
            display: flex;
            justify-content: center;
            
            .preco-anterior{
                span{
                    font-size: 13px;
                    text-decoration: line-through;
                    color: red;
                }
            }
        }

        .area-button{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;


            .button{
                display: flex;
                width: 100%;
                max-width: 90px;
                margin-left: 2px;
                justify-content: center;

                a{
                    text-decoration: none;
                    color: white;
                }

                &:hover {
                    border: 1px solid var(--rosaTn); /* Altere a cor e o estilo da borda conforme necessário */
                    border-radius: 5px;
                }
                
            }

            .button-comprar{
                display: flex;
                width: 100%;
                max-width: 90px;
                margin-left: 2px;
                justify-content: center;


                a{
                    width: 100%;
                    text-decoration: none;
                }

            }
        }

        span{
            margin: 4px;
            color: white;
            font-size: 16px;
            font-weight: bold;
        }

    }

    

    /* Telas pequenas (smartphones) */
    @media (max-width: 600px) {
        max-width: 250px;
        
        .area-title{
            height: auto;
            span{
                font-size: 16px;
                font-weight: bold;
            }
        }
        
    }

    &:hover{
        border: 1px solid #f53899;
    }
    
`;