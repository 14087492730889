import styled from "styled-components";

export const Container  = styled.header`
    grid-area: Header;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    background-image: linear-gradient(var(--azul), var(--azul),  var(--secondaryColor));

    

    /* Telas pequenas (smartphones) */
    @media (max-width: 600px) {
        display: block;
        height: auto;

    }

    /* Telas médias (tablets) */
    @media (max-width: 1024px) {

    }

    /* Telas extra grandes (largas) */
    @media (min-width: 1441px) {

    }
`;


export const AreaMascote = styled.div`
    display: block;
    margin-top: 82px;
    width: 30%;
    height: 400px;
    

    .mascote{
        display: flex;
        width: 100%;
        

        img{
            width: 100%;
            max-height: 400px;
        }
    }

    /* Telas pequenas (smartphones) */
    @media (max-width: 600px) {
        height: auto;


        .mascote{
            display: none;
        }

    }

    /* Telas médias (tablets) */
    @media (max-width: 1024px) {
        

        .mascote{
            margin-top: 20%;

            img{
                width: 100%;
                height: auto;
            }
        }
    }

    /* Telas extra grandes (largas) */
    @media (min-width: 1441px) {

    }

`;

export const AreaDestaque = styled.div`
    display: block;
    margin-top: 82px;
    width: 70%;
    height: 100%;
    


    .carousel-destaque{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .destaque-img{
        width: 250px;
        height: 190px;
        

        img{
            width: 100%;
            height: auto;
        }
    }
        
    

    .carousel-categorias-redondo {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        width: 100%;
        height: 200px;
        padding: 0px 3% 0px 3%;

        
    }

    /* Telas pequenas (smartphones) */
    @media (max-width: 600px) {

        width: 100%;

        .carousel-categorias-redondo {
            
            padding: 0px 0% 0px 0%;
            
        }

        .destaque-img{
            width: 100%;
            max-width: 250px;
            height: auto;
        }

    }    
`;