import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import ConteudoMain from '../../components/ConteudoMain/index';
import CarouselMain from '../../components/Carousel/CarouselMain';
import Layout from '../../components/Layout';
import { produtos } from '../../dados/produtos';


const Home = () => {
  const [produtosRecomendados, setProdutosRecomendados] = useState([]);
  const [produtosMelhoresCursos, setProdutosMelhoresCursos] = useState([]);
  const [produtosMelhoresCursosPagos, setProdutosMelhoresCursosPagos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      const recomendados = produtos.filter(produto => produto.categorias.includes(6)); // Categoria 6: Recomendado para Você
      const melhoresCursos = produtos.filter(produto => produto.categorias.includes(8)); // Categoria 8: Melhores Cursos
      const melhoresCursosPagos = produtos.filter(produto => produto.categorias.includes(7)); // Categoria 7: Melhores Cursos Pagos
      
      setProdutosRecomendados(recomendados);
      setProdutosMelhoresCursos(melhoresCursos);
      setProdutosMelhoresCursosPagos(melhoresCursosPagos);
      setLoading(false);
      
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Layout>
        <Container>
            <div className="area-publicidade">
                <div className="publicidade-lider">
                    GOOGLE ME PATROCINA;
                </div>
            </div>
            <ConteudoMain title="Recomendado para Você" linkVerTudo={`/produto/categoria/recomendado-para-voce`} conteudo="Carousel 1" backgroundimage="linear-gradient(var(--secondaryColor), var(--mainColor))">
                <CarouselMain produtos={produtosRecomendados} slidesToshow={3.47}/>
            </ConteudoMain>
            <ConteudoMain title="Melhores Cursos Pagos" linkVerTudo={`/produto/categoria/melhores-cursos-pagos`} conteudo="Carousel 2" backgroundimage="linear-gradient(var(--mainColor), var(--secondaryColor))">
                <CarouselMain produtos={produtosMelhoresCursosPagos} slidesToshow={3.47}/>
            </ConteudoMain>
            <ConteudoMain title="Melhores Cursos" linkVerTudo={`/produto/categoria/melhores-cursos`} conteudo="Carousel 2" backgroundimage="linear-gradient(var(--secondaryColor), var(--mainColor))">
                <CarouselMain produtos={produtosMelhoresCursos} slidesToshow={3.47}/>
            </ConteudoMain>
        </Container>
    </Layout>
  );
};

export default Home;
