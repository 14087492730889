import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 1000px;
    height: 200px;


    
    .cont-carousel{
        display: flex;
        width: 100%;
        height: 100%;
    }

    .carousel{
        width: 100%;
        
        height: 100%;
        
    }

    .area-card{
        width: 100%;
        max-width: 170px;
        height: auto;
 
    }

    
`;