"use client";
import React, { useEffect, useState } from 'react';
import {Container, AreaCards, AreaTitle, ContAreaCards} from './styles';
import Breadcrumbs from "../../components/Breadcrumbs";
import CardDestaque from '../../components/CardDestaque';
import Loading from '../../components/Loading/Loading';
import { useParams } from 'react-router-dom';
import { produtos } from '../../dados/produtos';
import Layout from '../../components/Layout';
import { categorias } from '../../dados/categorias';


const CategoriaDetail = () => {

    const { categoriaNomeUrl } = useParams();
    const [categoriaProdutos, setCategoriaProdutos] = useState([]);
    const [dataCategoria, setDataCategoria] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      if (categoriaNomeUrl) {
        const fetchProduto = async () => {
          try {
            // Simulando a busca de produtos por categoria
            const categoria = categorias.find(cat => cat.nomeUrl === categoriaNomeUrl);
  
            if (!categoria) {
              throw new Error('Categoria não encontrada');
            }
  
            const produtosFiltrados = produtos.filter(produto =>
              produto.categorias.includes(categoria.id)
            );
  
            setCategoriaProdutos(produtosFiltrados);
            setDataCategoria(categoria);
  
          } catch (err) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
        };
  
        fetchProduto();
      }
    }, [categoriaNomeUrl]);
  
    if (loading) {
      return (
        <Container>
          <Loading />
        </Container>
      );
    }
  
    if (error) {
      return <div>Erro: {error}</div>;
    }
  
    if (!dataCategoria) {
      return <div>Categoria não encontrada</div>;
    }
  
    const breadcrumbPaths = [
      { label: 'Home', href: '/' },
      { label: 'Categoria' },
      { label: dataCategoria.nome }
    ];
  
    return (
      <Layout>
        <Container>
          <div className="area-navegacao">
            <Breadcrumbs paths={breadcrumbPaths} />
          </div>
          <AreaTitle>
            <div className="divisor" />
            <div className="title">
              <span>{dataCategoria.nome}</span>
            </div>
          </AreaTitle>
          <ContAreaCards>
            <AreaCards>
              {Array.isArray(categoriaProdutos) && categoriaProdutos.map(produto => (
                <div className='card-area-categoria' key={produto.id}>
                  <CardDestaque
                    link={`/produto/${produto.slug}`}
                    title={produto.titulo}
                    imgCard={produto.imgCard}
                    imgAlt={produto.imgCardAlt}
                    precoAnterior={produto.precoAnterior}
                    precoAtual={produto.precoAtual}
                    rating={produto.rating}
                  />
                </div>
              ))}
            </AreaCards>
          </ContAreaCards>
        </Container>
      </Layout>
    );
  };
  
  export default CategoriaDetail;