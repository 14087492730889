//Donuts
export const donuts1 = '/imagens/donuts/1.png';
export const donuts4 = '/imagens/donuts/dona1.jpg';
export const donuts5 = '/imagens/donuts/dona2.jpg';
export const donuts6 = '/imagens/donuts/dona3.jpg';
export const donuts7 = '/imagens/donuts/6.png';
export const authorImage = '/imagens/donuts/autorimg.jpg';
//adestramento Canino
export const ades1 = '/imagens/adestramento/ades1.png';
export const ades2 = '/imagens/adestramento/ades2.jpg';
export const ades3 = '/imagens/adestramento/ades3.png';
export const ades5 = '/imagens/adestramento/ades5.png';
export const ades11 = '/imagens/adestramento/11.png';
export const ades25 = '/imagens/adestramento/25.png';
//Cortes de cabelo
export const barb1 = '/imagens/barbeiro/basico-black.jpg';
export const barb2 = '/imagens/barbeiro/barb2.png';
export const barb3 = '/imagens/barbeiro/barb3.jpg';
export const barb4 = '/imagens/barbeiro/profissional-padrao.jpg';
export const barb5 = '/imagens/barbeiro/19.jpg'; 
//Marmitas
export const marcard = '/imagens/marmitas/card.png';
export const marcapa = '/imagens/marmitas/capa.png';
export const mar2 = '/imagens/marmitas/2.png';
export const mar3 = '/imagens/marmitas/3.jpg';
export const mar5 = '/imagens/marmitas/5.jpg';
//radiestesia
export const radautor = '/imagens/radiestesia/perfilautor.png';
export const radCard = '/imagens/radiestesia/card.png';
export const radCapa = '/imagens/radiestesia/capa.png';
export const rad1 = '/imagens/radiestesia/2.webp';
export const rad2 = '/imagens/radiestesia/3.webp';
export const rad3 = '/imagens/radiestesia/4.webp';
//formaenriquecendo
export const formaCard = '/imagens/formaenriquecendo/card.png';
export const formaCapa = '/imagens/formaenriquecendo/img3.jpg';
export const formaImg2 = '/imagens/formaenriquecendo/img2.jpg';
export const formaImg3 = '/imagens/formaenriquecendo/img1.jpg';
export const formaImg1 = '/imagens/formaenriquecendo/imag1.png';
export const formaAutor = '/imagens/formaenriquecendo/autor.png';


const generateSlug = (titulo) => {
    return titulo
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9 ]/g, '')
        .trim()
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
};

const produtosData = [
  {
    id: 1,
    titulo: 'Curso de Donuts do zero - Céu Doce',
    subTitulo: 'MÉTODO PASSO A PASSO PARA FAZER E VENDER DONUTS SABOROSOS DO ABSOLUTO ZERO.',
    tituloDescricao: 'Mesmo que você não tenha experiência na cozinha: Ganhe a partir de R$4.000/mês vendendo Donuts AGORA',
    descricao: 'Aprenda a fazer Deliciosos Donuts do Absoluto Zero com a Céu Doce. Somos uma confeitaria de muito sucesso aqui na nossa cidade e queremos te ajudar a ter esse resultado também começando com os itens que você tem em casa e sem desperdício.',
    imgCard: donuts1,
    imgCardAlt: 'Imagem do card do curso',
    imgPrincipal: donuts7,
    imgPrincipalAlt: 'Imagem principal do curso',
    urlVideo: 'https://www.youtube.com/embed/BoEdmUu7CM0?controls=0&rel=0&playsinline=1&enablejsapi=1&origin=https%3A%2F%2Finvictuspublicidade.com.br&widgetid=1',
    precoAnterior: 100,
    precoAtual: 67,
    parcelaTexto:'ou em 9 x de R$ 8,56 no cartão',
    rating: 4,
    categorias: [1, 2, 1, 6, 7, 8, 9],
    tituloAutor:'Eu serei sua Professora!',
    authorName: 'Marcela Natalia',
    authorDescription: 'Meu nome é Marcela Natalia, sou fundadora da Céu Doce, uma confeitaria de sucesso aqui na minha cidade. Quando eu comecei minha jornada com donuts, foi exatamente como você está agora. No meu início, eu não tinha muito dinheiro para investir e muito menos para comprar equipamentos. Comecei do zero e consegui montar um negócio de sucesso!',
    authorImage: authorImage,
    authorImageAlt: 'Imagem da autora do curso',
    linkCompra: 'https://pay.hotmart.com/J86091487T?off=36pie5em&ref=D91740951A&bid=1720899007050',
    beneficios: [
      'PREPARO DA MASSA',
      'ASSANDO OU FRITANDO',
      'DECORAÇÃO E RECHEIO',
      'CURSO COMPLETO',
      'CERTIFICADO DE CONCLUSÃO'
    ],
    tituloResultados: 'AO FINAL DO CURSO, ESTE SERÁ O SEU RESULTADO:',
    resultados: [donuts4, donuts5, donuts6],
    faq: [
      {
        question: 'Dá pra ganhar dinheiro com donuts mesmo?',
        answer: 'Sim. Eu, Marcela, comecei do zero sem nenhum equipamento, somente com o conteúdo que eu ensino no curso, e consegui montar uma confeitaria de sucesso aqui na região.'
      },
      {
        question: 'Precisa comprar algum equipamento?',
        answer: 'Não. Os equipamentos vão te auxiliar quando você começar a ter muita demanda de pedidos, no entanto agora no início você não precisa de nada. Inclusive é possível fazer a massa na mão, sem a batedeira. E isso eu ensino no curso!'
      },
      {
        question: 'Vamos aprender a rechear e decorar?',
        answer: 'Sim, você vai aprender o passo a passo para fazer donuts. Prontos para embalar e vender!'
      },
      {
        question: 'Vai mostrar como fabricar embalagens artesanais?',
        answer: 'Simmm! Aqui na Céu Doce, reduzimos e muito o custo com a entrega com as nossas caixinhas artesanais que também ensinaremos no curso.'
      }
    ],
    testimonials: [
      {
        text: 'As aulas são bem didáticas e o suporte é excelente.',
        author: 'Maria'
      }
    ]
  },
 
  {
    id: 2,
    titulo: 'Adestramento Canino em Casa - Ebook',
    subTitulo: 'Quer saber como adestrar seu cão com apenas 20 minutos por dia?',
    tituloDescricao: 'Descubra como transformar o cão mais travesso, bagunceiro e complicado num animal de estimação bem comportado, calmo e dócil.',
    descricao: 'Ebook prático de adestramento canino em casa é perfeito para proprietários que desejam ter um cão obediente e bem comportado. Com técnicas comprovadas, você poderá treinar seu cão sem gastar muito e fortalecer a conexão com ele. Ensine comandos básico ao avançado e solucione problemas de comportamento de forma fácil e eficaz. Aproveite os bônus incluídos e comece a treinar seu cão imediatamente no conforto da sua casa! Descubra também como se tornar um adestrador profissional, tendo assim uma nova profissão!',
    imgCard: ades1,
    imgCardAlt: 'Imagem do card do curso',
    imgPrincipal: ades2,
    imgPrincipalAlt: 'Imagem principal do curso',
    urlVideo: 'https://www.youtube.com/embed/YDbIwMIDTps?si=AeLAKh3qaa2M4712',
    precoAnterior: 60,
    precoAtual: 34.90,
    parcelaTexto:'ou em 4 x de R$ 9,36 X no cartão',
    rating: 5,
    categorias: [1, 2, 1, 6, 7, 8, 9],
    tituloAutor:'Saiba mais sobre quem criou o conteúdo',
    authorName: 'Digital Jordão',
    authorDescription: `O adestramento canino parece ser complexo para
quem nunca estudou o tema antes, ou seja, é um adestrador
de primeira viagem. Contudo, quando se entende a
dinâmica do adestramento canino é possível adequar as
técnicas com mais clareza no seu pet. Este é o objetivo deste
eBook.`,
    authorImage: ades5,
    authorImageAlt: 'Imagem da autora do curso',
    linkCompra: 'https://go.hotmart.com/L94336191I?ap=2ec1',
    beneficios: [
      '13 Comandos básicos e avançados de adestramento',
      'Como criar obediência e confiança extrema em você em 15 dias',
      '4 Recompensas secretas que fazem os cães ficarem loucos para te agradar',
      'Um jeito certo de punir levemente sem precisar bater ou xingar',
      'Os piores erros na hora de educar qualquer cão',
      'Linguagem corporal canina com 28 comportamentos mais comuns',
      'E muitas outras técnicas e segredos que você não acha em nenhum lugar',
    ],
    tituloResultados: 'AO FINAL DO CURSO, ESTE SERÁ O SEU RESULTADO:',
    resultados: [ades3, ades11, ades25],
    faq: [
      {
        question: 'Quais são os tópicos que eu vou encontrar dentro do eBook?',
        answer: 'Introdução - O segredo da matilha - O líder excelente - Características de um líder excelente - Linguagem corporal canina -  Comandos Básicos - Comandos Avançados - Principais Equipamentos e Produtos - Como lucrar com adestramento canino - Conclusão'
      },
      {
        question: 'Quanto tempo até começar a ver os resultados?',
        answer: 'Você pode começar a ver os resultados com extrema rapidez … Em alguns dias e às vezes até mesmo horas depois de começar. Quanto mais você fizer disso parte de sua rotina diária, melhores serão os seus resultados.'
      },
      {
        question: 'Como obtenho acesso instantâneo ao GUIA?',
        answer: 'Clique no botão de compra'
      },
      {
        question: 'Como este guia é entregue?',
        answer: 'Você terá acesso instantâneo a uma versão em PDF deste guia, juntamente com links de download para os bônus. O acesso é imediato logo após a confirmação do pagamento… Você pode começar agora mesmo.'
      }
    ],
    testimonials: [
      {
        text: 'Otimo produto, meu cachorro está muito mais obediente a partir de agora, so tenho a agradecer.',
        author: 'ARIEL'
      },
      {
        text: 'muito didático adorei, em poucos dias meu cachorro ja tava obedecendo alguns comandos',
        author: 'JULIANA'
      },
      {
        text: 'Muito bom',
        author: 'DIOGO'
      }
    ]
  },

  {
    id: 3,
    titulo: 'Curso de Barbeiro Profissional 2024',
    subTitulo: 'Aprenda os cortes mais desejados de 2024 mesmo que você NUNCA TENHA PEGADO EM UMA TESOURA ANTES e tenha sua agenda cheia de clientes todos os dias.',
    tituloDescricao: 'VEJA COMO SE TORNAR UM BARBEIRO PROFISSIONAL COMEÇANDO DO ABSOLUTO ZERO',
    descricao: `Nosso curso é um treinamento completo 100% em vídeo aulas que vai fazer você chegar ao mais alto nível, e se destacar dos Barbeiros mais conceituados.

Acesse nossas aulas on-line quando e onde quiser e a qualquer hora do dia.

Nosso curso é indicado tanto para quem está iniciando agora e também para quem já trabalha na área e está querendo se aperfeiçoar.`,
    imgCard: barb1,
    imgCardAlt: 'card com o certificado',
    imgPrincipal: barb2,
    imgPrincipalAlt: 'imagem card principal',
    urlVideo: 'https://www.youtube.com/embed/h7-qTvlKjQw?si=sBFP_duMm_xQ4TeS',
    precoAnterior: 250,
    precoAtual: 147.00,
    parcelaTexto:'ou em 12X de R$ 15,56 no cartão',
    rating: 5,
    categorias: [1, 2, 1, 6, 7, 8, 9],
    tituloAutor:'Saiba mais sobre quem criou o conteúdo',
    authorName: `Fábrica de Barbeiros`,
    authorDescription: `A "Fábrica de Barbeiros Cursos Online" está na Hotmart desde de 2016 atuando neste mercado, desde lá foram muitas conquistas profissinais que o Marketing proporcionou a nossa empresa. Nosso objetivo aqui é proporcionar a melhor experiência possível para cada de um de nossos alunos e também afiliados, ajudando a cada um deles alcançarem os seus objetivos e realizarem os seus sonhos. O sucesso nasce do querer, da determinação e persistência em se chegar a um objetivo. Mesmo não atingindo o alvo, quem busca e vence obstáculos, no mínimo fará coisas admiráveis.`,
    authorImage: barb4,
    authorImageAlt: 'Imagem da autora do curso',
    linkCompra: 'https://go.hotmart.com/I94336172M?ap=2dad',
    beneficios: [
      'ACESSO IMEDIATO A +70 DE AULAS QUE VÃO MUDAR A SUA VIDA!',
      'MATERIAIS DE USO',
      'TÉCNICA DE TESOURA',
      'CORTE SOCIAL (MÁQUINA)',
      'CORTE SOCIAL (TESOURA)',
      'TÉCNICA DA SOBRANCELHA',
      'HIGH FADE (DEGRADÊ ALTO)',
      'RAZOR PART',
      'FLAT TOP (CORTE DO WILL SMITH)',
      'E MUITO MAIS...',
    ],
    tituloResultados: 'AO FINAL DO CURSO, ESTE SERÁ O SEU RESULTADO:',
    resultados: [barb4, barb3, barb5],
    faq: [
      {
        question: 'Como funciona o curso?',
        answer: 'Nosso curso é totalmente online e logo após realizar a sua inscrição, você irá receber os dados de acesso da nossa plataforma onde você terá acesso total ao curso.'
      },
      {
        question: 'O que eu irei Receber?',
        answer: 'Você vai receber acesso exclusivo à nossa plataforma onde contém todas as videoaulas, materiais para baixar, bônus exclusivos e também o seu certificado.'
      },
      {
        question: 'Tenho que pagar mensalidade?',
        answer: 'Não, o pagamento do curso é único e não possui mensalidades ou qualquer outra taxa adicional. Resumindo, você irá pagar apenas R$ 147,00 e nada mais do que isso.'
      },
      {
        question: 'O curso é presencial?',
        answer: 'Não, nosso treinamento é 100% online e você poderá acessá-lo do seu celular, computador ou tablet.'
      }
    ],
    testimonials: [
      {
        text: 'Curso incrível me ajudou muito, estão de parabéns',
        author: 'ERIC'
      },
      {
        text: 'Gostei do curso!',
        author: 'JOSÉ'
      },
      {
        text: 'O curso foi de excelência, parabéns ao curso!',
        author: 'ANTONIO'
      },
      {
        text: 'Muito bom o curso, parabéns!',
        author: 'ADINALDO'
      }
    ]
  },
  {
    id: 4,
    titulo: 'CURSO DE MARMITAS FITNESS SAUDÁVEIS - MARMITARIA FIT: VERSÃO DELUXE ONLINE - 2024',
    subTitulo: 'VOCÊ JÁ PENSOU EM TRANSFORMAR SUA VIDA COM UMA ALIMENTAÇÃO SAUDÁVEL?',
    tituloDescricao: 'MARMITAS SAUDÁVEIS FITNESS',
    descricao: 'Venha descobrir os segredos de uma alimentação saudável, desde a embalagem às técnicas de armazenamento, congelamento e transporte. Agora com vídeo-aulas e um receituário completo elaborado pelo especialista Hernane Fonso. Torne o preparo das refeições diárias mais nutritivo e saboroso colocando a mão na massa, seja para cozinhar em casa ou para empreender. Conheça o sucesso do nosso curso relatado por usuários e empreendedores que aumentaram sua renda mensal através da venda de marmitas/quentinhas fitness.',
    imgCard: marcard,
    imgCardAlt: 'imagem card',
    imgPrincipal: marcapa,
    imgPrincipalAlt: 'imagem capa',
    urlVideo: 'https://www.youtube.com/embed/-t-ijQ0xPAc?si=WUAtKyIsWlOC_YXL',
    precoAnterior: 160,
    precoAtual: 99.90,
    parcelaTexto:'ou em 12 x de R$ 9,97 no cartão',
    rating: 5,
    categorias: [1, 2, 1, 6, 7, 8, 9],
    tituloAutor:'Saiba mais sobre quem criou o conteúdo',
    authorName: 'Home Sale Digital',
    authorDescription: ``,
    authorImage: marcard,
    authorImageAlt: 'Imagem da autora do curso',
    linkCompra: 'https://go.hotmart.com/G94349314K?ap=a902',
    beneficios: [
      'Módulo 03: Cuidados e preparativos',
      'Módulo 04: Planejando o congelamento',
      'Módulo 05: Receitas  Fitness',
      'Módulo 06: Receitas LowCarb',
      'Módulo 07: Receitas vegetarianas',
      'Módulo 08: Receitas tradicionais',
      'Módulo 09: Empreendedorismo com as marmitas',
      'Módulo 10: Conclusão do curso',
      'CERTIFICADO GRÁTIS!!!'
    ], 
    tituloResultados: 'AO FINAL DO CURSO, ESTE SERÁ O SEU RESULTADO:',
    resultados: [mar2, mar3, mar5],
    faq: [
      {
        question: 'QUANTO TEMPO DE ACESSO AO CURSO ON-LINE EU TEREI ?',
        answer: 'Após a compra ser confirmada, todo o conteúdo fica disponível para você durante 12 meses. Você pode acessar a plataforma e fazer o download dos conteúdos para utilizar quando e onde estiver.'
      },
      {
        question: 'NO CONTEÚDO DO CURSO TEM VÍDEO-AULAS ?',
        answer: 'Sim, o curso tem vídeo-aulas divididas em módulos. Nos módulos básico e intermediários os alunos cursam o conteúdo de nivelamento, onde aprendem as receitas, tudo sobre normas, dicas e regras da alimentação saudável. Já o módulo avançado é para quem quer empreender no setor de alimentação saudável.'
      },
      {
        question: 'OS ALUNOS POSSUEM UM GRUPO EXCLUSIVO?',
        answer: 'Sim, o alunos matriculados participam de um grupo exclusivo no Facebook para compartilhar suas dúvidas, receitas e estratégias de negócio.'
      },
      {
        question: 'COMO IREI ACESSAR O CURSO ON-LINE?',
        answer: 'Os conteúdos, de vídeos, e-books e todos os bônus são disponibilizados na área de alunos. O cadastro é enviado por e-mail logo após a confirmação do pagamento.'
      }
    ],
    testimonials: [
      {
        text: 'Com o curso eu consigo fazer minha renda na minha casa e com certificado. Começamos com poucas unidades e hoje temos que recusar pedidos de venda pois não temos mais espaço para armazenar',
        author: 'Carolina Santos'
      },
      {
        text: 'Vídeos com professor gato e super didático.Faço uso próprio. Minha vida é muito corrida então preciso disso! Tiro um dia da semana e faço as marmitas do mês todo. Quando alguma colega não quer levar marmita no trabalho elas compram e gostam.',
        author: 'Mariana Bezerra'
      },
      {
        text: 'O curso é ótimo, o professor  explica tudo nos vídeos, a variedade de receitas me permite ter uma vida mais saudável. Eu uso pra mim mesma, mas minha tia vende.',
        author: 'Sônia Soares'
      }
    ]
  },
  {
    id: 5,
    titulo: 'Formação em Radiestesia Sensitiva - 2024',
    subTitulo: 'A Radiestesia é a Ciência que permite detectar, comparar e classificar as radiações e vibrações das quais o universo é formado.',
    tituloDescricao: 'Formação em Radiestesia Sensitiva',
    descricao: `A Radiestesia é a Ciência que permite detectar, comparar e classificar as radiações e vibrações das quais o universo é formado.

A Radiestesia pode ser desenvolvida por qualquer pessoa, já que é uma faculdade inerente a todos.

O Curso é ministrado em 5 Módulos, que compreendem a técnica completa. São mais de 80 videoaulas (gravadas) teóricas, práticas e com Certificação em Radiestesia Sensitiva.

Você conta com todo o meu acompanhamento pessoal em cada videoaula no CHAT de perguntas e respostas.

Além disso há aulas online periódicas com a turma pela plataforma do zoom para acompanhamento e dúvidas.

Objetivos do Curso:

Desenvolver e capacitar (profissionais ou não) ao uso prático da técnica de Radiestesia para tratamento pessoal, empresarial e de imóveis.

A quem se Destina:

Qualquer pessoa. Não há pré-requisito para seu aprendizado.

O que vamos aprender:

– Noções teóricas de Radiestesia e Radiônica

– Utilizar os principais instrumentos radiestésicos

– Técnica completa de tratamento

* Curso Totalmente Prático. Desde o primeiro módulo o aluno já adquire a aptidão ao uso do pêndulo e ferramentas para atendimento.

“Este produto não substitui o parecer médico profissional. Sempre consulte um médico para tratar de assuntos relativos à saúde.”`,
    imgCard: radCard,
    imgCardAlt: 'imagem destaque',
    imgPrincipal: radCapa,
    imgPrincipalAlt: 'imagem card',
    urlVideo: 'https://www.youtube.com/embed/i4lnaGzP20Y?si=4_XTIGbUh8cVzdkG',
    precoAnterior: '2.500',
    precoAtual: '1.590,00',
    parcelaTexto:'ou em 12 x de R$ 158,69 no cartão',
    rating: 4,
    categorias: [1, 2, 1, 6, 7, 8, 9],
    tituloAutor:'Saiba mais sobre quem criou o conteúdo',
    authorName: 'Eduardo Freitas',
    authorDescription: `Há mais de 15 anos ensino Radiestesia do Básico ao Avançado. No Curso de Formação em Radiestesia Sensitiva você conta com todo o meu acompanhamento pessoal em cada videoaula (aulas gravadas) e ao final você ganha um Certificado, se assim desejar atuar profissionalmente.

Videoaulas com uma didática simples para você colocar em prática e melhorar a sua Vida naquilo que é mais importante para Você!

Seja na sua área pessoal ou profissional.

A Radiestesia Sensitiva te possibilita também, uma Expansão de Consciência com técnicas da Física e Mecânica Quântica.`,
    authorImage: radautor,
    authorImageAlt: 'Imagem da autor do curso',
    linkCompra: 'https://go.hotmart.com/W94352299J?ap=1137',
    beneficios: [
      "Radiestesia",
      "Radiônica",
      "Física e Mecânica Quântica",
      "Medicina vibracional",
      "Corpos sutis",
      "Instrumentos",
      "Orientação para a prática",
      "Réguas Radiestésicas",
      "Exercícios Básicos",
      "Técnica",
      "Sistema Biológico",
      "Cargas Negativas",
      "Bastão Atlante",
      "Imóveis – Residências e Empresas (Geobiologia e Geopatogenia)",
      "Projeção / Desdobramento Astral",
      "Captação psíquica",
      "Trauma",
      "Desencarnados"
    ],
    tituloResultados: 'Imagens do Curso',
    resultados: [rad1, rad2, rad3],
    faq:[
      {
        question: "O que é Radiestesia?",
        answer: "Radiestesia é a sensibilidade às radiações emitidas por corpos. Ela permite detectar e medir essas radiações através de instrumentos específicos como pêndulos e varetas."
      },
      {
        question: "O que é Radiônica?",
        answer: "Radiônica é uma técnica que utiliza equipamentos para emitir ondas de forma que visam harmonizar e equilibrar energias, comumente usadas para tratamentos de saúde e equilíbrio emocional."
      },
      {
        question: "Quais são os conceitos fundamentais da Física Quântica abordados no curso?",
        answer: "O curso aborda conceitos como o experimento da dupla fenda, emaranhamento quântico e outros princípios básicos que explicam a natureza das partículas subatômicas e suas interações."
      },
      {
        question: "O curso inclui práticas com instrumentos radiestésicos?",
        answer: "Sim, o curso inclui orientação prática sobre o uso de diversos instrumentos radiestésicos, como pêndulos e réguas radiestésicas, além de exercícios para aprimorar a técnica."
      }
    ],
    testimonials: [
      {
        text: "O curso de Radiestesia e Radiônica mudou a minha vida. Aprendi técnicas que me ajudaram a equilibrar minhas energias e melhorar minha saúde.",
        author: "Maria Silva"
      },
      {
        text: "As aulas são bem estruturadas e fáceis de entender. O instrutor é muito experiente e compartilha conhecimento valioso sobre medicina vibracional e física quântica.",
        author: "João Pereira"
      },
      {
        text: "Recomendo este curso para todos que desejam aprofundar seus conhecimentos em terapias energéticas. Os exercícios práticos foram especialmente úteis para meu aprendizado.",
        author: "Ana Costa"
      }
    ]
  },

  {
    id: 6,
    titulo: 'Formula Enriquecendo 2024',
    subTitulo: 'Faça dinheiro todos os dias trabalhando em casa utilizando o seu celular conectado a internet!',
    tituloDescricao: 'Fórmula Enriquecendo Online 2024',
    descricao: `Fórmula Enriquecendo Online é um treinamento desenvolvido para quem quer iniciar no Mercado Digital do absoluto zero, o treinamento ensina técnicas de vendas on-line que pode ser aplicada para qualquer tipo de produto físico ou digital.

O programa de mentoria Fórmula Enriquecendo On-line foi desenvolvido por Tondimas Torres, uma das maiores referências no marketing digital na America latina, e hoje o projeto conta com mais de 220 mil alunos em 17 países.

o time interno conta com mais de 30 colaboradores empenhados em sempre proporcionar uma excelente experiência para nosso alunos e afiliados.`,
    imgCard: formaCard,
    imgCardAlt: 'imagem de card',
    imgPrincipal: formaCapa,
    imgPrincipalAlt: 'capa imagem',
    urlVideo: 'https://www.youtube.com/embed/CswifNdj6CM?si=g7fPvNF9gTBhkFMo',
    precoAnterior: 450,
    precoAtual: 297,
    parcelaTexto:'ou em 12 x de R$ 31,44 no cartão',
    rating: 4,
    categorias: [1, 2, 1, 6, 7, 8, 9],
    tituloAutor:'Saiba mais sobre quem criou o conteúdo',
    authorName: 'Tondimas Torres',
    authorDescription: `Tondimas Torres é o idealizador do Treinamento Fórmula Enriquecendo Online, criador do Método T.O.M e já faturou mais de R$1 milhão de reais na plataforma Hotmart em apenas 3 meses, se tornando um Hotmart Black.

Depois de ter falhado diversas vezes, desenvolveu um Método Exclusivo que aplicou para conseguir criar negócios onlines sólidos e lucrativos a partir do absoluto zero.

Agora compartilha tudo através do seu treinamento e segue ajudando todas as pessoas que querem ter um negócio online, ampliar seus ganhos, e aumentar sua liberdade financeira e geográfica.

Atualmente tem mais de 150 mil alunos já aplicando e tendo resultados com o Treinamento e continua trazendo novas pessoas todo santo dia a começarem a sua transformação e criarem o seu negócio online.`,
    authorImage: formaAutor,
    authorImageAlt: 'Imagem da autora do curso',
    linkCompra: 'https://go.hotmart.com/O94384019L?ap=35e4',
    beneficios: [
      'Certificado de conclusão',
      'Mentorias semanais ao vivo',
      'Atualizações periódicas',
      'Grupo de suporte',
      'Premiações para alunos que se destacam.',
    ],
    resultados: [formaImg2, formaImg3, formaImg1],
    faq: [
      {
        question: 'Como eu recebo o acesso ao treinamento?',
        answer: 'O curso é 100% online e você recebe o acesso do curso pelo e-mail logo após a confirmação de compra. Lembrando que compras feitas pelo boleto tem até 48 horas úteis para serem confirmadas.'
      },
      {
        question: 'Posso parcelar no boleto?',
        answer: 'Não, parcelamos somente via cartão de crédito em até 12x'
      },
      {
        question: 'Quanto tempo levo para ter resultados?',
        answer: 'Os resultados vão depender apenas de você. Não é dinheiro fácil, você precisa pegar e aplicar as estratégias com consistência. Mas claro, com dedicação e fazendo do jeito certo o seu sucesso será inevitável.'
      },
      {
        question: 'Terei suporte dentro do curso?',
        answer: 'Sim. Temos um grupo e um chat onde a nossa equipe vai tirar todas as suas dúvidas caso você tenha. Você também terá acesso a um grupo com milhares de alunos.'
      }
    ],
    testimonials: [
      {
        text: 'Estou tendo um pouco de dificuldade, mais estou amando o curso. Tenho certeza que aos poucos tudo vai dar certo.',
        author: 'LIDIANE'
      },
      {
        text: 'Eu amei esse produto, foi uma nova é ótima experiência.',
        author: 'CLEBER'
      },
      {
        text: 'Foi fácil entender.',
        author: 'GILNEY'
      }
    ]
  },


];

export const produtos = produtosData.map(produto => ({
    ...produto,
    slug: generateSlug(produto.titulo)
}));

/*
{
  id: ,
  titulo: '',
  subTitulo: '',
  tituloDescricao: '',
  descricao: '',
  imgCard: ades1,
  imgCardAlt: '',
  imgPrincipal: ades2,
  imgPrincipalAlt: '',
  urlVideo: '',
  precoAnterior: 60,
  precoAtual: 34.90,
  parcelaTexto:'ou em 4 x de R$ 9,36 no cartão',
  rating: 4,
  categorias: [1, 2, 1, 6, 7, 8, 9],
  tituloAutor:'Saiba mais sobre quem criou o conteúdo',
  authorName: '',
  authorDescription: ``,
  authorImage: ades5,
  authorImageAlt: 'Imagem da autora do curso',
  linkCompra: '',
  beneficios: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  resultados: [ades3, ades11, ades25],
  faq: [
    {
      question: '',
      answer: ''
    },
    {
      question: '',
      answer: ''
    },
    {
      question: '',
      answer: ''
    },
    {
      question: '',
      answer: ''
    }
  ],
  testimonials: [
    {
      text: '',
      author: ''
    },
    {
      text: '',
      author: ''
    },
    {
      text: '',
      author: ''
    }
  ]
},
*/
