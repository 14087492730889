import styled from "styled-components";

export const Container = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    max-width: 85px;
    border-radius: 5px;
    background-color: ${({ $color }) => $color || '#e3023c'};
    color: white;
    cursor: pointer;
    border: none; /* Remover a borda padrão do botão */
    outline: none; /* Remover o contorno de foco */
    font-size: 16px;
    
`;
