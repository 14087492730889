import React from "react";
import { Container, LinkMenu, AreaEmblema } from "./styles";


const BarMain = () => {
  // Usar uma variável para armazenar o valor de 'window' pode ajudar a evitar problemas de renderização no servidor
  const isClient = typeof window !== 'undefined';

  return(
    <Container>
      <AreaEmblema>
        <div className="emblema">
          <a href="/">
            {isClient && <img src='/imagens/Emblema.png' alt="Emblema do site" />}
          </a>
        </div>
      </AreaEmblema>
      <LinkMenu>
      <div className="cont-menu">
        <a href="http://blog.aiqueproduto.com.br" target="_blank" rel="noopener noreferrer">Blog</a>
      </div>
      </LinkMenu>
    </Container>
  );
}
export default BarMain;
