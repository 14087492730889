import styled from "styled-components";


export const BreadcrumbContainer = styled.nav`
  display: flex;
  max-width: 100%;
  height: 100%;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  
  
  a {
    color: white;
    text-decoration: none;
    //margin-right: 5px;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .area-breadcrumb {
    margin-right: 5px;
    color: #33328F;
  }

  .barra{
    color: white;
    margin-left: 5px;
  }
`;