import styled from "styled-components";

export const Container = styled.footer`
    grid-area: Footer;
    display: flex;
    width: 100%;
    height: auto;
    background-color: var(--mainColor);  
`;

export const ConteudoFooter = styled.div`
    display: block;
    width: 100%;
    height: auto;
    
    
    
    .bar-icon-pagamentos{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70px;
        background-color: #3F508B;

        .icons{
            img{
                margin-left: 20px;
                width: 50px;
                height: 50px;
            }
        }
    }

    .cont-footer{
        display: flex;
        width: 100%;
        height: 250px;
        justify-content: center;
    }


    .footerWrapper{
        display: flex;
        width: 100%;
        height: 100px;
        max-width: 1000px;
        justify-content: space-between;
        margin-top: 4%;
    }

    .footerSection{
        flex: 1;
        text-align: center;

        span {
            display: block;
            font-weight: bold;
            margin-bottom: 10px;
            color: #f53899;
            font-size: 18px;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                margin: 5px 0;
            }

            a {
                text-decoration: none;
                color: white;
                transition: color 0.3s;

                &:hover {
                    color: #1785C2;
                }
            }
        }
    }

    .bar-copy{
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: center;
        align-items: center;
        background-color: #1785C2;
        
        span{
            font-size: 16px;
            color: white;
        }
    }

    /* Telas pequenas (smartphones) */
    @media (max-width: 600px) {

        .bar-icon-pagamentos{  
            height: auto;

            .icons{
                img{
                    margin-left: 10px;
                }
            }
        }


        .footerWrapper{
            display: block;
        }

        .footerSection{
            span {
                font-size: 16px;
            }

            a {
                font-size: 14px;
            }
        }

        .bar-copy{
            height: auto;

            span{
                margin: 5px 10% 5px 10%;
                font-size: 14px;
            }
            
        }
    }

    /* Telas médias (tablets) */
    @media (max-width: 1024px) {
       
    }

    /* Telas extra grandes (largas) */
    @media (min-width: 1441px) {

    }
`;
