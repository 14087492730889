import React from "react";
import { AreaMascote, Container, AreaDestaque } from "./styles";

import CarouselHeaderRed from "../Carousel/CarouselHeaderRed/index";



const Header = () => {
    return(
        <Container>
            <AreaDestaque>
                <div className="carousel-destaque">
                    <div className="destaque-img">
                        <a href="/produto/categoria/melhores-cursos-gratuitos">
                            <img src="/imagens/cgratuitos.png" alt="Cursos Gratuitos"/>
                        </a>
                        
                    </div>
                    <div className="destaque-img">
                        <a href="/produto/categoria/melhores-cursos-pagos">
                            <img src="/imagens/cpago.png" alt="Cursos Pagos"/>
                        </a>
                    </div>
                </div>
                <div className="carousel-categorias-redondo">
                    <CarouselHeaderRed/>
                </div>
            </AreaDestaque>        
        </Container>
    );
}
export default Header;