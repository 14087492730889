import React from 'react';
import { Container, FullStar, EmptyStar } from './styles';

const Avaliacao = ({ rating }) => {
  const totalStars = 5;
  const fullStars = Math.floor(rating);
  const emptyStars = totalStars - fullStars;

  return (
    <Container>
      {[...Array(fullStars)].map((_, index) => (
        <FullStar key={`full-${index}`}>&#9733;</FullStar>
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <EmptyStar key={`empty-${index}`}>&#9734;</EmptyStar>
      ))}
    </Container>
  );
};

export default Avaliacao;

/*
Se fullStars for 3 e emptyStars for 2:

[...Array(fullStars)] se torna [undefined, undefined, undefined].
[...Array(emptyStars)] se torna [undefined, undefined].
O map gera:
Três componentes FullStar com chaves full-0, full-1, full-2.
Dois componentes EmptyStar com chaves empty-0, empty-1.

*/