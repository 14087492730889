import React, { useEffect, useState } from 'react';
import {Container} from "./styles";
import Slider from "react-slick";
import CardRedondo from "../../CardRedondo/index";
import { categorias } from '../../../dados/categorias'; 


const CarouselRedondo = () => {

    //setttings do Slick
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 5.8, //aqui
        slidesToScroll: 1,
        initialSlide: 0,
        className: "carousel",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 1320,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 1053,
                settings: {
                    slidesToShow: 3.6,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3.3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2.9,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2.6,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 813,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2.7,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 416,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 340,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };

    return(
        <Container>
            <div className="cont-carousel">
                <Slider {...settings}>
                    {Array.isArray(categorias) && categorias.map((categoria) => (
                        <div  className="area-card" key={categoria.id}>  
                            <CardRedondo 
                                imgCard={categoria.imgCard}
                                titleCard={categoria.nome} 
                                altCard={categoria.nome} 
                                nomeUrl={`/produto/categoria/${categoria.nomeUrl}`}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </Container>
        
    );
}
export default CarouselRedondo; 