import React from "react";
import {Container} from "./styles";
import Botao from "../Botao";
import Avaliacao from "../Avaliacao";


const CardDestaque = ({title, imgCard, imgAlt, rating, link, precoAnterior, precoAtual}) => {
    return(
        <Container>
            <img src={imgCard} alt={imgAlt}/>
            <div className="area-title">
                <div className="titulo">
                    <span>{title}</span>
                </div>
                <div className="avaliacao">
                    <Avaliacao rating={rating}/>
                </div>
                <div className="preco">
                    <div className="preco-anterior">
                        <span>R$ {precoAnterior}</span>
                    </div>
                    <div className="preco-atual">
                        <span>R$ {precoAtual}</span>
                    </div>
                </div>
                <div className="area-button">
                    <div className="button">
                        {link ? (
                            <a href={link}>
                                Detalhes
                            </a>
                        ) : (
                            <span>Detalhes</span>
                        )}
                    </div>
                    <div className="button-comprar">
                        {link ? (
                            <a href={link}>
                                <Botao color="#1785C2" title="Comprar"/>
                            </a>
                        ) : (
                            <Botao color="#1785C2" title="Comprar"/>
                        )}
                        
                    </div>
                </div>
                

            </div>
            
        </Container>
        
    );
}
export default CardDestaque;