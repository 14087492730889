import React from "react";
import { Container, ConteudoFooter } from "./styles";


const Footer = () => {
    return(
        <Container>
            <ConteudoFooter>
                <div className="bar-icon-pagamentos">
                    <div className="icons">
                        <img src="/imagens/checkout.svg" alt="icone visa e mastercard"/>
                        <img src="/imagens/dlocal_pix.svg" alt="icone pix"/>
                        <img src="/imagens/picpay_wallet.svg" alt="icone picpay"/>
                        <img src="/imagens/dlocal_boleto.svg" alt="icone boleto"/>
                        
                    </div>
                </div>
                <div className="cont-footer">
                    <div className="footerWrapper">
                        <div className="footerSection">
                            <span>Sobre a Empresa</span>
                            <ul>
                                <li><a href="#">Sobre nós</a></li>
                                <li><a href="#">Fale Conosco</a></li>
                            </ul>
                        </div>

                        <div className="footerSection">
                            <span>Comprar</span>
                            <ul>
                                <li><a href="#">Como comprar</a></li>
                                <li><a href="#">Lista de Coursos</a></li>
                            </ul>
                        </div>

                        <div className="footerSection">
                            <span>Ajuda</span>
                            <ul>
                                <li><a href="#">Perguntas Frequentes</a></li>
                            </ul>
                        </div>
        
                    </div>
                </div>
                <div className="bar-copy">
                    <span>Copyright &copy; {new Date().getFullYear()} by SamAITech. Todos os direitos reservados.</span>
                </div>
                

            </ConteudoFooter>
        </Container>
    );
}
export default Footer;